/*
General Styles
*/
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500&family=Oswald&display=swap");
@import "colours.scss";

html,
body {
  width: 100%;
  height: 100%;
}

.page-wrapper {
  width: 100%;
  min-height: 100vh;
}

.md-only {
  display: none !important;
}

.artsy-parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../assets/artsy.jpg");
  height: 15vh;
  width: 100%;
}

#ctaContainer {
  height: calc(264px * 3);
  width: 264px;
  margin: 64px auto;
}

.cta-container {
  position: relative;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 200px;
  height: 200px;
  font-size: 22px;
  margin: 32px;
  background: linear-gradient(to right, $primaryColor 6px, transparent 6px) 0 0,
    linear-gradient(to right, $quaternaryColor 6px, transparent 6px) 0 100%,
    linear-gradient(to left, $secondaryColor 6px, transparent 6px) 100% 0,
    linear-gradient(to left, $tertiaryColor 6px, transparent 6px) 100% 100%,
    linear-gradient(to bottom, $primaryColor 6px, transparent 6px) 0 0,
    linear-gradient(to bottom, $secondaryColor 6px, transparent 6px) 100% 0,
    linear-gradient(to top, $tertiaryColor 6px, transparent 6px) 0 100%,
    linear-gradient(to top, $tertiaryColor 6px, transparent 6px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 60px 60px;
}

.cta-container a {
  color: black;
}

.cta-container a:hover {
  text-decoration: none;
  color: #fff;
}

.cta-container:hover {
  background: $primaryColor;
  border-radius: 50%;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.cta-container:hover a {
  background: $primaryColor;
  color: #fff;
}

.react-cookie-law-dialog {
  top: unset !important;
  bottom: 0;
}

#testimonialCarousel {
  width: 100%;
  background: $quaternaryColor;
}

.testimonial {
  min-height: 50vh;
  width: 75%;
  margin: 0 auto;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0;

  p {
    font-size: 18px;
    font-style: italic;
    color: #fff;
  }

  span {
    float: right;
    font-size: 12px;
    color: #fff;
  }
}

@media screen and (min-width: 768px) {
  .md-only {
    display: block !important;
  }

  .sm-only {
    display: none !important;
  }

  #ctaContainer {
    height: 264px;
    width: calc(248px * 3);
    margin: 64px auto;
  }

  .cta-container {
    margin: 24px;
  }

  .testimonial {
    p {
      font-size: 22px;
    }

    span {
      font-size: 16px;
    }
  }
}

@media screen and (min-width: 1024px) {
  #ctaContainer {
    height: 334px;
    width: calc(334px * 3);
    margin: 64px auto;
  }

  .cta-container {
    width: 270px;
    height: 270px;
    font-size: 24px;
    margin: 32px;
  }
}

/*
NavBar
*/

.navbar {
  height: 120px;
}

#responsive-navbar-nav {
  width: 100%;
  background: #fff;
  z-index: 2;
}

.navbar a {
  font-size: 18px;
  min-width: 180px;
  text-align: center;
}

.navbar-brand {
  width: 180px;
  margin: 0 auto !important;
  position: relative;
}

.navbar-brand img {
  width: 100%;
}

.desktop-nav-logo {
  display: none !important;
}

.navbar-toggler {
  position: absolute;
  right: 8px;
}

@media screen and (min-width: 960px) {
  .navbar {
    height: 80px;
    margin-bottom: 40px;
  }

  .navbar-brand {
    top: 20px;
  }

  .navbar a {
    font-size: 20px;
  }

  .mobile-nav-logo {
    display: none !important;
  }

  .desktop-nav-logo {
    display: block !important;
  }
}

@media screen and (min-width: 1400px) {
  .navbar {
    height: 120px;
  }

  .navbar-brand {
    width: 250px;
  }

  .navbar a {
    font-size: 24px;
  }
}

/*
Footer
*/

footer {
  width: 100%;
  background-color: $primaryColor;
  display: flex !important;
  justify-content: center;
  flex-direction: column;
}

footer h5 {
  color: #fff;
  font-weight: bold;
}

footer a {
  color: #fff;
  font-size: 18px;
}

footer a:hover {
  color: #fff;
}

footer img {
  height: 25px;
  width: auto;
  margin: 8px 12px 8px 0;
}

footer section {
  display: flex;
  flex-direction: column;
  margin: 16px auto;
  width: 200px;
}

@media screen and (min-width: 768px) {
  footer {
    flex-direction: row;
    justify-content: space-evenly;
  }

  footer section {
    margin: 32px 0;
  }
}
