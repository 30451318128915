@import "colours.scss";

h1,
h2,
h3 {
  border-bottom: 4px solid $primaryColor;
}

h1,
h2,
h3,
h4 {
  font-family: "Oswald", sans-serif;
}

p,
a,
input {
  font-family: "Comfortaa", cursive;
}
